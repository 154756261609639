import { EPlanAction } from '../constants/plan.const';

export enum PaymentStatusEnum {
  InitTransaction = -1,
  SuccessfulTransaction = 11,
  TransactionIsNotCompleted = 22,
  ErrorTransaction = 33,
  HandlingTransactions = 44,
}
export enum EPaymentProvider {
  Vnpay = 'VN_PAY',
  Momo = 'MOMO',
  Zalopay = 'ZALO_PAY',
  Transfer = 'TRANSFER',
}

export enum EPaymentPlatform {
  MOBILE = 'MOBILE',
  WEB = 'WEB',
}

export const CPaymentProvider = {
  [EPaymentProvider.Vnpay]: {
    key: 'VN_PAY',
    name: 'VNPAY',
  },
  [EPaymentProvider.Momo]: {
    key: 'MOMO',
    name: 'MOMO',
  },
  [EPaymentProvider.Zalopay]: {
    key: 'ZALO_PAY',
    name: 'ZaloPay',
  },
};

export enum EPaymentPurpose {
  PURCHASE = 'PURCHASE',
  RENEW = 'RENEW',
  UPGRADE = 'UPGRADE',
  EXTEND = 'EXTEND',
  GET_MORE = 'GET_MORE',
}

export interface IPaymentPayload {
  planId: string;
  purpose: EPlanAction | null;
  period: number | null;
  totalUsers: number | null;
}

// Generated by https://quicktype.io

export interface IPaymentVoucher {
  token: string;
  organizationId: number;
  userId: number;
  satisfySpecialOffer: boolean;
  currentPlanId: string;
  activeUsers: number;
  paymentPurpose: 'GET_MORE' | 'PURCHASE' | 'EXTEND' | null;
  period: number;
}

// Generated by https://quicktype.io

export interface IPaymentVoucherDetail {
  code: string;
  valueInPercent: number;
  valueInMoney: number;
  currency: string;
  name: string;
  description: string;
  validFrom: string;
  validTo: string;
  isValid: boolean;
  messageCode: EPaymentVoucherResponseCode | null;
  message: string;
  category: 'VOUCHER' | 'COUPON';
}
export enum EPaymentVoucherResponseCode {
  VOUCHER_NOT_FOUND = 'VOUCHER_NOT_FOUND',
  VOUCHER_INVALID = 'VOUCHER_INVALID',
  VOUCHER_LIMIT_REACHED = 'VOUCHER_LIMIT_REACHED',
  VOUCHER_EXPIRED = 'VOUCHER_EXPIRED',
  VOUCHER_TOO_EARLY = 'VOUCHER_TOO_EARLY',
  VOUCHER_BELONG_TO_OTHER_PHONE_NUMBER = 'VOUCHER_BELONG_TO_OTHER_PHONE_NUMBER',
  VOUCHER_BELONG_TO_OTHER_EMAIL_ADDRESS = 'VOUCHER_BELONG_TO_OTHER_EMAIL_ADDRESS',
  VOUCHER_BELONG_TO_OTHER_ORGANIZATION = 'VOUCHER_BELONG_TO_OTHER_ORGANIZATION',
  VOUCHER_BELONG_TO_OTHER_USER = 'VOUCHER_BELONG_TO_OTHER_USER',
  VOUCHER_VALID = 'VOUCHER_VALID',
  SUSPICIOUS_REQUEST_DETECTED = 'SUSPICIOUS_REQUEST_DETECTED',
}

// Generated by https://quicktype.io
export interface IRegisterLicense {
  id: string;
  licensePackageId: string;
  status: string;
  type: string;
  planId: string;
  name: string;
  phone: string;
  address: string;
  email: string;
  representativeName: string;
  position: string;
  taxCode: string;
  contractFileHtml: string;
  package: ILicensePackage;
}
export interface ILicensePackage {
  currencyUnit: string;
  description: string;
  id: string;
  index: number;
  isActive: boolean;
  isDefault: boolean;
  name: string;
  rank: string;
  totalAmount: number;
  totalMember: number;
  type: string;
}

export interface ICreatePayLicenseUrl {
  licenseRegistrationId: string;
  originalAmount: number;
  totalAmount: number;
  paymentMethod: string;
  currencyUnit: string;
  locate: string;
  payerName: string;
  payerEmail: string;
  payerPhone: string;
  ipAddress?: string;
  platform?: string;
  voucherCode?: string;
}
